@font-face {
  font-family: "UnileverShilling";
  src: url("../../assets/fonts/UnileverShilling.woff");
}

@font-face {
  font-family: "UnileverShillingBold";
  src: url("../../assets/fonts/UnileverShillingBold.woff");
}

@font-face {
  font-family: "UnileverShillingBold";
  src: url("../../assets/fonts/UnileverShillingBold.woff");
}

@font-face {
  font-family: "UnileverShillingMedium";
  src: url("../../assets/fonts/UnileverShillingMedium.woff");
}

@import "./variables";
@import "./layout";

.form-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 7px;
  input[type="radio"] {
    margin: 0;
    width: 20px;
    height: 20px;
    border: solid 2px #000;
    background-color: #fff;
  }
  input[type="checkbox"] {
    margin: 0;
    width: 20px;
    height: 20px;
    border: solid 2px #000;
  }
  label {
    margin: 0 0 0 10px;
  }
}

.theme-tooltip {
  background-color: #fff !important;
  color: black !important;
  font-weight: bold;
  font-size: 12px;
  font-family: UnileverShilling;
  max-width: 600px !important;
}

// material dialog

.mat-dialog-container {
  border-radius: 0px !important;
}
