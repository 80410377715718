$header-height: 58px;
$footer-height: 61px;
.layout-wrapper {
  // HEADER
  .header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    width: 100%;
    height: $header-height;

    .container-left {
      display: flex;
      .navbar-brand {
        text-align: center;
        font-family: "UnileverShilling";
        font-size: 30px;
        font-weight: 700 !important;
        cursor: pointer;
      }

      .access-report-header {
      }
      .navbar-item {
        text-align: center;
        font-family: "UnileverShilling";
        font-size: 16px;
        font-weight: 700;
        padding-top: 15px;
        cursor: pointer;
      }
    }
    .container-right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .user {
        img {
          margin: 10px;
        }
      }
      .user-name {
        text-align: center;
        font-family: "UnileverShillingMedium";
        font-size: 14px;
        font-weight: 500;
      }
      .dropdown {
        position: absolute;
        top: $header-height;
        right: 0;
        padding: 20px;
        background-color: #fff;
        text-align: center;
        font-family: "UnileverShillingMedium";
        font-size: 14px;
        font-weight: 500;

        .dropdown-item {
          cursor: pointer;
        }

        &.collapse {
          display: none;
        }
      }
    }
  }

  .page-wrapper {
    min-height: calc(100vh - $header-height - $footer-height);
  }

  // FOOTER
  .footer-container {
    width: 100%;
    height: 61px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background-color: #efefef;
    cursor: pointer;
  }
}

.white {
  color: white !important;
}

.blueBackground {
  background-color: $app-background-blue;
}
