$app-background-color: #fff;
$app-background-blue: #005eef;

$dark-blue: #005eef;
$white: #fff;
$green:#00b190;
$yellow: #fae44c;




